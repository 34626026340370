class ZIDXAccountErrorLogView implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountErrorLogViewContainer";
    }

    getMatchURL() {
        return "/account/error-logs/view";
    }

    render() {
    }
}